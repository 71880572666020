import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { isEmpty } from 'lodash';

/**
 * Styling Guide For Amplitude Events:
 *
 * Case 1: Event Specific To A Page / Tab
 * Tab / Page - Event Name
 * Examples: Gifting - View Analytics, Campaigns - View Campaigns, Talent Card - offer custom rate
 *
 * Case 2: General Events
 * General - Event Name
 * Examples: General - Viewed Profile, General - Clicked Button, General - Issue Discount Code
 *
 *
 * How to call amplitude events:
 * window.__ADD_EVENT__('Event Name', { keys: and, values: for, context: here });
 */

/*
  While accessing the redux store directly is not recommended, we do it here because we want to access
  the user object globally without requiring it to be passed down through props. Please do not replicate
  this pattern elsewhere in the codebase unless you are sure you need to.
*/
import storeData from '../configureStore';
import {
  isSimulatingUser,
  isEUUser,
  isBrand,
  isShopper,
  getBrandSubscriptionBundle,
  getBrandWinningMerchant,
  getShopifyIntegration,
  getBrandSubscriptionInvoices
} from './user_helpers';
import { getUserTierDisplay } from './tier_helpers';
import { isSubscribedToFeature } from './subscription_helpers';
import { cleanBrandTier } from './brand_tier_helpers';

export const initializeAmplitude = () => {
  // Ensure we only do this once per page load
  if (!isAmplitudeTrackingEnabled()) return;

  // Initialize Session Replay
  const user = storeData.store.getState().user;
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: window.__IS_PROD__ ? 1 : 1, // 100% in prod, 100% in dev for now
    ...(isEUUser(user) ? { serverZone: 'EU' } : {}) // For GDPR compliance
  });
  amplitude.add(sessionReplayTracking);

  amplitude.init(
    isBrand(user)
      ? process.env.REACT_APP_AMPLITUDE_KEY_2025
      : isShopper(user)
      ? process.env.REACT_APP_AMPLITUDE_KEY_2025_SHOPPERS
      : process.env.REACT_APP_AMPLITUDE_KEY_2025_CREATORS,
    null,
    {
      includeReferrer: true,
      includeUtm: true,
      includeGclid: true
    }
  );
};

export const logAmplitudeEvent = (name, data = {}) => {
  if (!isAmplitudeTrackingEnabled()) return;

  amplitude.logEvent(name, {
    ...data,
    brand: null,
    user: null, // Make sure not to pass the whole user object

    // Extra information to help debug certain cases
    ScreenResolution: `${window.screen.width}x${window.screen.height}` // ex: "1920x1080"
  });
};

export const isAmplitudeTrackingEnabled = reduxUser => {
  /*
    Since Amplitude charges on a per-user basis, we only want to track users who are brands for now,
    soon we will be able to track all logged in users.

    We must pass in the profile if we are doing this in the reducer, as you are not allowed to access
    the redux store directly in reducers.
  */
  const user = reduxUser || storeData.store.getState().user;
  const userProfile = user.profile;

  // Do not track if we are simulating a user
  if (isSimulatingUser(user) && window.__IS_PROD__) return false;

  // If they are a brand, track them
  if (userProfile.brand?.id) return true;

  // Track all shoppers
  if (isShopper(user)) return true;

  // If they are a creator, we only add them if they have ever been a trendsetter or icon
  if (userProfile.tier?.bestTier <= 2) return true;

  // If they are a shopper, track them (for now until we understand how they use the platform and have tuned it sufficiently)
  if (userProfile.isShopper) return true;

  // If we are not on dev, track just for testing
  // if (window.__IS_DEV__) return true;
  if (window.__IS_DEV__) return false;

  return false;
};

export const syncAmplitudeUserProfile = user => {
  if (!isAmplitudeTrackingEnabled(user)) return;

  // Set User_id to something readable
  const profile = user.profile;
  amplitude.setUserId(profile.brand?.name || profile.username);

  // Set other User Properties
  const identifyObj = new amplitude.Identify();
  identifyObj.set('User_id', profile.id);
  identifyObj.set('Email', profile.email);
  identifyObj.set('Name', profile.name);
  if (profile.brand?.id) {
    const brand = profile.brand;

    identifyObj.set('Brand', brand.name);
    identifyObj.set('Account Manager', brand.account_manager?.name || 'None');
    identifyObj.set('Package', getBrandSubscriptionBundle(user)?.title || '-');
    identifyObj.set('Network', getBrandWinningMerchant(user)?.source || '-');
    identifyObj.set('Shopify App', !!getShopifyIntegration(user).id);
    identifyObj.set('Shopify App - Tracking', !!getShopifyIntegration(user).allowAffiliateTracking);
    identifyObj.set('Program Report Tier', cleanBrandTier(brand.tier).currentTier.label);
    identifyObj.set('First Billing Date', getBrandSubscriptionInvoices(user)?.[0]?.createdAt || '-');
    identifyObj.set('Commission Rate', getBrandWinningMerchant(user) || '-');

    identifyObj.set('Module - Core', isSubscribedToFeature(user, 'CORE'));
    identifyObj.set('Module - Gifting', isSubscribedToFeature(user, 'GIFTING'));
    identifyObj.set('Module - Unlimited Gifting', isSubscribedToFeature(user, 'UNLIMITED_GIFTING'));
    identifyObj.set('Module - Social Listening', isSubscribedToFeature(user, 'SOCIAL_MENTIONS_FEED'));
    identifyObj.set('Module - Advanced Analytics', isSubscribedToFeature(user, 'ADVANCED_TALENT_ANALYTICS'));
    identifyObj.set('Module - Campaigns', isSubscribedToFeature(user, 'COLLABORATE'));
    identifyObj.set('Module - Campaign Management', isSubscribedToFeature(user, 'CAMPAIGN_MANAGEMENT'));
    identifyObj.set('Module - Communications', isSubscribedToFeature(user, 'UNLIMITED_CHAT'));
    identifyObj.set('Module - Opportunities', isSubscribedToFeature(user, 'OPPORTUNITIES'));
    identifyObj.set('Module - Sourcing Support', isSubscribedToFeature(user, 'EXPERT_SOURCING'));
    identifyObj.set('Module - Gifting Management', isSubscribedToFeature(user, 'GIFTING_MANAGEMENT'));

    if (!isEmpty(brand.stats)) {
      identifyObj.set('Icon Promoters', brand.stats.numIconsPromoting);
      identifyObj.set('trendsetter Promoters', brand.stats.numTrendsettersPromoting);
      identifyObj.set('Ambassador Promoters', brand.stats.numAmbassadorsPromoting);
      identifyObj.set('Promoters', brand.stats.numPromoting);

      identifyObj.set('Clicks', brand.stats.totalClicks);
      identifyObj.set('Monthly Clicks', brand.stats.monthlyClicks);
      identifyObj.set('Clicks DTC', brand.stats.totalClicksDTC);
      identifyObj.set('Monthly Clicks DTC', brand.stats.monthlyClicksDTC);

      identifyObj.set('Volume', brand.stats.totalVolume);
      identifyObj.set('Monthly Volume', brand.stats.monthlyVolume);
      identifyObj.set('Volume DTC', brand.stats.totalVolumeDTC);
      identifyObj.set('Monthly Volume DTC', brand.stats.monthlyVolumeDTC);

      identifyObj.set('Commissions', brand.stats.totalCommissions);
      identifyObj.set('Monthly Commissions', brand.stats.monthlyCommissions);
      identifyObj.set('Commissions DTC', brand.stats.totalCommissionsDTC);
      identifyObj.set('Monthly Commissions DTC', brand.stats.monthlyCommissionsDTC);

      identifyObj.set('Orders', brand.stats.totalOrders);
      identifyObj.set('Monthly Orders', brand.stats.monthlyOrders);
      identifyObj.set('Orders DTC', brand.stats.totalOrdersDTC);
      identifyObj.set('Monthly Orders DTC', brand.stats.monthlyOrdersDTC);
    }
  } else if (profile.isShopper) {
    // Nothing for now
  } else {
    identifyObj.set('Username', profile.username);
    identifyObj.set('Tier', getUserTierDisplay(profile.tier.tier));
    identifyObj.set('Best Tier', getUserTierDisplay(profile.tier.bestTier));
    identifyObj.set('True Score', profile.tier.trueScore);
    identifyObj.set('Account Manager', profile.account_manager?.name || 'None');
  }
  amplitude.identify(identifyObj);
};
