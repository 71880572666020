import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-light-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import './ProductHeart.scss';

import { isProductIdFavorited } from '../../../Helpers/shop_helpers';

import ProductHeartOverlay from './ProductHeartOverlay';

const ProductHeart = props => {
  const { user, product } = props;

  // Show Product Heart Overlay
  const [isShowingOverlay, setIsShowingOverlay] = React.useState(false);
  const toggleOverlay = e => {
    e.stopPropagation();
    e.preventDefault();
    setIsShowingOverlay(!isShowingOverlay);
    window.__ADD_EVENT__('Shop - Product Heart Click', {
      Product_id: product?.id,
      Title: product?.title,
      URL: product?.fallbackUrl
    });
  };

  // Favoriting
  const isFavorited = isProductIdFavorited(user, product?.id);

  const additionalClasses = {
    loading: props.isLoading,
    favorited: isFavorited,
    favoriting: props.isFavoriting,
    unfavoriting: props.isUnfavoriting
  };

  const heart = (
    <div className='favorite-outer-container'>
      <div onClick={toggleOverlay} className={cn('favorite', additionalClasses)}>
        <FontAwesomeIcon
          style={{
            ...(props.size && { fontSize: props.size })
          }}
          icon={isFavorited ? faHeartSolid : faHeart}
        />
      </div>
    </div>
  );
  return isShowingOverlay ? (
    <ProductHeartOverlay product={product} closeOverlay={toggleOverlay} attribution={props.attribution}>
      {heart}
    </ProductHeartOverlay>
  ) : (
    heart
  );
};

ProductHeart.propTypes = {
  user: PropTypes.object.isRequired,
  product: PropTypes.object,

  // If we want to attribute this to a curator or group
  attribution: PropTypes.object, // { Curator_id, CuratorGroup_id }

  // Optional UI
  size: PropTypes.number,
  isLoading: PropTypes.bool // Fades while loading containing cell
};

export default ProductHeart;
